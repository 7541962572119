<template>
	<div class="mb-6 w-full mt-6">
		<div class="flex items-center">
			<span class="icon-list text-base text-gray mr-6"></span>
			<h4 class="text-base text-gray font-semibold">ITEM LIST</h4>
		</div>

		<div class="w-full">
			<TableGista :items="rfqItemListsPagination?.content"
				:headers="headers"
				:sort-order="sortParams"
				:options.sync="queryParams"
				:show-not-found="false"
				:show-pagination="true"
				:searchable="false"
				:server-side="true"
				class="mt-4"
				@update:options="updatePagination"
				@refresh="fetchData">
				<template v-slot:no="{ slotProps }">
					{{ slotProps.index + 1 }}
				</template>
			</TableGista>
		</div>
	</div>
</template>

<script>
export default {
	name: "ItemList",
	data() {
		return {
			headers: [
				{
					value: "no",
					text: "No",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "jenis",
					text: "Jenis",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "productLayanan",
					text: "Produk/Layanan",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "subProduct",
					text: "Sub Product",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "description",
					text: "Description",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "lokasi",
					text: "Lokasi",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "qty",
					text: "Qty",
					clickable: false,
					sortable: false,
					align: "start",
				},
				{
					value: "uom",
					text: "UoM",
					clickable: false,
					sortable: false,
					align: "start",
				}

			],
			sortParams: [
			],
			queryParams: {
				totalItems: 0,
				itemsPerPage: 10,
				page: 0
			},
			slotProps: {
				index: 0
			},
		}
	},
	computed: {
		quotationInformation() {
			return this.$store.state.quotationDetail.quotationInformation
		},
		rfqItemListsPagination: {
			get() {
				return this.$store.state.quotationDetail.rfqItemListsPagination
			},
			set(value) {
				this.$store.commit('rfq/SET_RFQ_ITEM_LISTS_PAGINATION', value)
			}
		}
	},
	watch: {
		rfqItemListsPagination() {
			this.queryParams = {
				totalItems: this.rfqItemListsPagination?.totalElements,
				itemsPerPage: this.rfqItemListsPagination?.size,
				page: this.rfqItemListsPagination?.number + 1
			}
		}
	},
	methods: {
		updatePagination(e) {
			this.queryParams = {
				page: e.page,
				itemsPerPage: e.itemsPerPage,
				totalItems: e.totalItems
			}
		},
		fetchData() {
			this.$store.dispatch('quotationDetail/getQuotationItemListPagination', { rfqId: this.$route.params.quotationId, page: this.queryParams.page, size: this.queryParams.itemsPerPage })
		}
	},
	async mounted() {
		await this.$store.dispatch('quotationDetail/getQuotationItemListPagination', { rfqId: this.$route.params.quotationId, page: 0, size: 10 })
	}
}
</script>